<template>
  <section class="about-page">
    <h1>About Florin Ghenade</h1>
    <article>
      <p>
        Florin Ghenade (n. 17 martie 1982, Roman, județul Neamț) este artist fotograf, trăiește și lucrează în București. Este licențiat al Facultății de Arte Plastice, secția Foto-Video. Înainte de opta pentru facultatea de Arte, urmează cursurile Facultății de Filosofie a Universității București. Cunoscut pentru proiectul „Arbori Bătrâni” expus la Muzeul Țăranului Român în perioada 17 noiembrie 2018-10 martie 2019, o documentare vizuală a valorilor naturale și patrimoniale, o poveste despre lumină și întuneric, Florin Ghenade este preocupat de fotografie ca mediu și nu ca mijloc de documentare, Florin Ghenade propune proiecte care vorbesc despre estetica imaginii. Fotografia, în acest caz, nu e un instantaneu, un moment surprins spontan, ci rezultatul unei căutări, al unui proces artistic atât intelectual - de concept, cât și tehnic - de realizare. Cu acest gând, natura, peisajul, sunt reprezentate de elemente definitorii, sustrase parcă din mediul natural și repovestite prin momentele ce le carcaterizează pe fiecare în parte.
        Expune la București, Cluj-Napoca, Șona (Făgăraș), Timișoara, iar în 2015 lucrările sale au fost parte din pavilionul României la Cvadrienala de Scenografie din Praga. În 2016, proiectul „Arbori Bătrâni” este expus în Essen, Germania la Contemporary Art Ruhr.
        Pe langa proiecte personale, lucreaza comisionat in proiecte de fotografie comerciala, unde urmareste sa aduca valoare comerciala proiectelor cu miza artistica si viceversa. Colaborarea cu Imago Mundi sau cu platforma ConnectArts, fiind exemple recente in acest sens.
      </p>

      <p>
        <b>contact:</b>
        <br>
        email: florin@ghenade.ro
        <br>
        <a href="tel.0040726536631">0726536631</a>
      </p>
    </article>
  </section>
</template>

<script>
  export default {
    name: 'About'
  }
</script>

<style lang="scss">
  .about-page {
    font-size: 2rem;
    max-width: 700px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 24px;
    a {
      text-decoration: none;
    }
  }
</style>
